import * as React from "react"
import Layout from "../components/layout"
import {} from "./requests.module.css"
import RequestForm from '../components/request-form'
import {RequestLanding, RequestInstructions } from '../components/request-landing'
import { Seo } from "../components/seo"
import { graphql } from "gatsby"

export default function RequestsPage({ data: { allSanityRequestForm } }) {
  const [bookRequest, setBookRequest] = React.useState(true)

  const requestImage = allSanityRequestForm.edges[0].node.requestsImage.asset.gatsbyImageData
  const bookRequestInstructions = allSanityRequestForm.edges[0].node._rawBookRequest
  const collectionRequestInstructions= allSanityRequestForm.edges[0].node._rawCollectionRequest

  return (
    <Layout containerStyle={"hasLanding"}>
      <Seo title="Requests" />
      <RequestLanding 
        isBookRequest={bookRequest} 
        setBookRequest={setBookRequest}
        requestImage={requestImage}
      />
      <RequestInstructions 
        isBookRequest={bookRequest}
        bookRequestInstructions={bookRequestInstructions}
        collectionRequestInstructions={collectionRequestInstructions}
      />
      <RequestForm 
        isBookRequest={bookRequest} 
        setBookRequest={setBookRequest} 
      />
    </Layout>
  )
}

export const query = graphql`
  {
    allSanityRequestForm {
      edges {
        node {       
          _rawBookRequest
          _rawCollectionRequest
          requestsImage {
            asset {
              gatsbyImageData
            }
          }
      }
    }
  }
}
`