import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { slogan } from './large-slogan.module.css'
const BlockContent = require('@sanity/block-content-to-react')

const LargeSlogan = () => {
  const { allSanitySiteSettings } = useStaticQuery(graphql`
    query {
      allSanitySiteSettings {
        edges {
          node {
            _rawTagline
          }
        }
      }
    }
  `)
  
  return (
    <div className={slogan}>
      <BlockContent blocks={allSanitySiteSettings.edges[0].node._rawTagline} />
    </div>
  )
}

export default LargeSlogan