import * as React from "react"
import { useForm } from "react-hook-form";

import { formHeader, requestForm, errorSpan, shippingDetails, selected, unselected, confirmation } from "./request-form.module.css"

const Confirmation = () => {
  return (
    <div className={confirmation}>
      <p>Your request has been submitted! We'll email you when your order is ready.</p>
    </div>
  )
}

const CollectionRequest = ({ onSubmit }) => {
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
  const watchShippingType = watch("shippingType", "pickup"); // you can supply default value as second argument
  return (
    <form className={requestForm} onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="email">
        <span>Your Email*</span>
        {errors.email && <span className={errorSpan}>This field is required!</span>}

      </label>
      <input type="email" id="email" placeholder="Your answer" {...register("email", { required: true })} />

      <label htmlFor="request">
        <span>What book(s) can we help you find?*</span>
        {errors.request && <span className={errorSpan}>This field is required!</span>}
      </label>
      <input type="text" id="request" placeholder="Your answer" {...register("request", { required: true })} />

      <label htmlFor="budget">
        <span>What is your budget?*</span>
        {errors.budget && <span className={errorSpan}>This field is required!</span>}
      </label>
      <input type="text" id="budget" placeholder="Your answer" {...register("budget", { required: true })} />

        <label>
          <span>Shipping or in-store pickup?*</span>
          {errors.shippingType && <span className={errorSpan}>This field is required!</span>}
        </label>
        <div className={shippingDetails}>            
          <p>
            <input 
              type="radio" 
              {...register("shippingType", { required: true })} 
              id="pickup" 
              name="shippingType" 
              value="pickup" 
            />
            <span onClick={()=> setValue("shippingType", "pickup")}>I'll pick this up in store at 47 Orchard Street</span>
          </p>
          <p>
            <input 
              type="radio" 
              {...register("shippingType", { required: true })} 
              id="ship" 
              name="shippingType" 
              value="ship" 
            />
            <span onClick={()=> setValue("shippingType", "ship")}>Please ship this to me</span></p>
        </div>

      {watchShippingType==="ship" && 
      <>
        <label htmlFor="address">
            <span>Mailing address:*</span>
            {errors.address && <span className={errorSpan}>This field is required!</span>}
        </label>
        <input type="text" id="address" placeholder="Your answer" {...register("address", { required: watchShippingType==="ship" })} />
      </>
      }

      <label htmlFor="name">
        <span>Name*</span>
        {errors.name && <span className={errorSpan}>This field is required!</span>}
      </label>
      <input type="text" id="name" placeholder="Your answer" {...register("name", { required: true })} />

      <label htmlFor="phone">
        <span>Phone Number*</span>
        {errors.phone && <span className={errorSpan}>This field is required!</span>}
      </label>
      <input type="tel" id="phone" placeholder="Your answer" {...register("phone", { required: true })} />

      <label htmlFor="questions">
        <span>Questions & Comments</span>
      </label>
      <input type="text" id="questions" placeholder="Your answer" {...register("questions", { required: false })} />
    
      <input type="submit" value="Submit Form" />
    </form>
  )
}
const BookRequest = ({ onSubmit }) => {
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
  const watchShippingType = watch("shippingType", "pickup"); // you can supply default value as second argument

  return (
    <form className={requestForm} onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="email">
        <span>Your Email*</span>
        {errors.email && <span className={errorSpan}>This field is required!</span>}

      </label>
      <input type="email" id="email" placeholder="Your answer" {...register("email", { required: true })} />

      <label htmlFor="request">
        <span>What book(s) can we help you find?*</span>
        {errors.request && <span className={errorSpan}>This field is required!</span>}
      </label>
      <input type="text" id="request" placeholder="Your answer" {...register("request", { required: true })} />

      <label htmlFor="budget">
        <span>What is your budget?*</span>
        {errors.budget && <span className={errorSpan}>This field is required!</span>}
      </label>
      <input type="text" id="budget" placeholder="Your answer" {...register("budget", { required: true })} />

        <label>
          <span>Shipping or in-store pickup?*</span>
          {errors.shippingType && <span className={errorSpan}>This field is required!</span>}
        </label>
        <div className={shippingDetails}>            
          <p>
            <input 
              type="radio" 
              {...register("shippingType", { required: true })} 
              id="pickup" 
              name="shippingType" 
              value="pickup" 
            />
            <span onClick={()=> setValue("shippingType", "pickup")}>I'll pick this up in store at 47 Orchard Street</span>
          </p>
          <p>
            <input 
              type="radio" 
              {...register("shippingType", { required: true })} 
              id="ship" 
              name="shippingType" 
              value="ship" 
            />
            <span onClick={()=> setValue("shippingType", "ship")}>Please ship this to me</span></p>
        </div>

      {watchShippingType==="ship" && 
      <>
        <label htmlFor="address">
            <span>Mailing address:*</span>
            {errors.address && <span className={errorSpan}>This field is required!</span>}
        </label>
        <input type="text" id="address" placeholder="Your answer" {...register("address", { required: watchShippingType==="ship" })} />
      </>
      }


      <label htmlFor="name">
        <span>Name*</span>
        {errors.name && <span className={errorSpan}>This field is required!</span>}
      </label>
      <input type="text" id="name" placeholder="Your answer" {...register("name", { required: true })} />

      <label htmlFor="phone">
        <span>Phone Number*</span>
        {errors.phone && <span className={errorSpan}>This field is required!</span>}
      </label>
      <input type="tel" id="phone" placeholder="Your answer" {...register("phone", { required: true })} />

      <label htmlFor="questions">
        <span>Questions &amp; Comments</span>
      </label>
      <input type="text" id="questions" placeholder="Your answer" {...register("questions", { required: false })} />
    
      <input type="submit" value="Submit Form" />
    </form>
  )
}

const bookRequestEndpoint = 'https://0e6efab054bb1eb81bf2a2782e147d49.m.pipedream.net'
const collectionRequestEndpoint = 'https://bb2ca2f81ada7dd842fe28f4aa908f43.m.pipedream.net'

const RequestForm = ({ isBookRequest, setBookRequest }) => {
  const [submitted, setSubmitted] = React.useState(false)

  const onSubmitBookRequest = async data => {
    setSubmitted(true)
    const res = await fetch(bookRequestEndpoint, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  };
  const onSubmitCollectionRequest = async data => {
    setSubmitted(true)
    const res = await fetch(collectionRequestEndpoint, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  };

  return (
    <div>
      <header className={formHeader}>
        <button onClick={() => setBookRequest(true)} className={isBookRequest ? selected : unselected}>Book Request Form</button>
        <button onClick={() => setBookRequest(false)} className={!isBookRequest ? selected : unselected}>Curated Collection Form</button>
      </header>
    {
      submitted ?
      <Confirmation />
      :
      (isBookRequest ?
        <BookRequest onSubmit={onSubmitBookRequest} /> 
        : 
        <CollectionRequest onSubmit={onSubmitCollectionRequest} />
      )
    }
    </div>
  )
}

export default RequestForm