import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { formInstructions, landing, floating, formSelect, bookRequest, collectionRequest, background, receipt, selectedForm, unselectedForm } from "./request-landing.module.css"
import LargeSlogan from './large-slogan'
const BlockContent = require('@sanity/block-content-to-react')

const RequestInstructions = ({ isBookRequest, bookRequestInstructions, collectionRequestInstructions }) => {
  return (
  <div className={formInstructions}>
    <img src="https://cdn.shopify.com/s/files/1/0583/3202/0894/files/paperclip.svg?v=1627568636" alt="paperclip" />
    {
      isBookRequest ?
        <BlockContent blocks={bookRequestInstructions} />
      :
        <BlockContent blocks={collectionRequestInstructions} />
    }
    <img src="https://cdn.shopify.com/s/files/1/0583/3202/0894/files/paperclip.svg?v=1627568636" alt="paperclip" />
  </div>
  )
}

const RequestLanding = ({ isBookRequest, setBookRequest, requestImage }) => {
  return (
    <div className={landing}>
      <div className={background}>
        <LargeSlogan />
      </div>
        <div 
          onClick={() => setBookRequest(true)} 
          className={`${formSelect} ${bookRequest} ${isBookRequest ? selectedForm : unselectedForm}`}>Sweet Pickle <br /><span>book request</span><br />Form.</div>
        <GatsbyImage
          alt={""}
          image={requestImage}
          className={floating}
          loading={"lazy"}
          style={{gridArea: "img", height: "100%", width: "100%", justifySelf: "center", alignSelf: "end"}}
          imgStyle={{ objectFit: "contain"}}
          placeholder="none"
          layout="constrained"
        />   
        <div onClick={() => setBookRequest(false)} className={`${formSelect} ${collectionRequest} ${!isBookRequest ? selectedForm : unselectedForm}`}>Request a <br /><span>curated</span><br />collection</div>
    </div>
  )
}
export { RequestLanding, RequestInstructions}